@import "button-mixins";

.postbus {

    &.is-tabbing {
        outline-color: #ffffff;

        .main-menu {
            a:focus,
            button:focus,
            div:focus,
            .options-field input:focus + svg,
            .autocomplete-panel button.close-btn:focus,
            .oebb-dropdown-panel__btn-close--active {
                @media @big-devices-and-smaller {
                    outline-color: #ffffff;
                }
            }
        }

        .main-nav ul > li > a:focus,
        .main-nav ul > li > .nav-sub-panel-link:focus,
        ul.footer-nav a:focus,
        .footer-home-link,
        .image-title-teaser > div a:focus,
        .social-nav a:focus {
            outline-color: #ffffff;
        }
    }

    header .logo-link {
        width: 216px;

        img {
            width: 216px;
        }

        @media @big-devices-and-smaller {
            padding: 22px 0 10px;
        }

        @media @small-devices {
            width: 185px;
            padding: 14px 0 10px;

            img {
                width: 185px;
            }
        }

        @media screen and (max-width: 420px) {
            width: 100px;
            padding: 19px 0 10px;

            img {
                width: 100px;
            }
        }
    }

    @media @screen-lg-min {
        header .logo-link {
            top: 90px;
        }

        header.is-sticky .logo-link {
            top: 100px;
        }
    }

    .service-nav {
        a:focus, a:hover, a:active {
            .ic-external-link {
                background-image: url(../images/icons/spritemap-postbus.svg#linkextern-view);
            }
        }
    }

    .lang-nav a:focus,
    .lang-nav a:hover {
        color: #ffffff;
        background: #6E6E6E;
        border-radius: 2px;
    }

    a:focus .ic-close,
    a:hover .ic-close,
    a:active .ic-close {
        background-image: url(../images/icons/spritemap-white.svg#close-view);
    }

    a:focus .ic-external-link,
    a:hover .ic-external-link,
    a:active .ic-external-link {
        background-image: url(../images/icons/spritemap-lightgray.svg#linkextern-view);
    }

    .image-title-teaser div {
        max-width: 56%;
    }

    @media @small-medium-devices {
        .image-title-teaser div {
            max-width: 100%;
        }
    }

    .oebb-input:focus,
    .oebb-autocomplete-input-field--active .oebb-input {
        box-shadow: 0 2px 0 0 @tint;
    }

    .oebb-dropdown-option .oebb-dropdown-option__link:focus,
    .oebb-dropdown-option .oebb-dropdown-option__link:hover,
    .oebb-dropdown-option .oebb-dropdown-option__link:active,
    .oebb-dropdown-option__link:focus,
    .oebb-dropdown-option__link:hover,
    .oebb-dropdown-option__link:active {
        color: @tint;
    }

    .scroll-panel ul a:active,
    .scroll-panel ul a:focus,
    .scroll-panel ul a:hover {
        background-color: #eaeaea;
    }

}



