.action-btn-chat:hover .ic-chatbot-white,
.action-btn-chat.action-btn--inversed:hover .ic-chatbot-red {
    background-image: url(../images/icons/spritemap-postbus.svg#chatbot-oebb-view);
}

.chat-dsgvo-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999999;

    width: 360px;
    max-height: ~'calc(100vh - 40px)';
    max-width: ~'calc(100vw - 40px)';
    padding: 10px;
    overflow: auto;

    background: white;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    transform: translate(0, 100vh);
    transition: transform .75s ease;

    &--visible {
        transform: translate(0, 0);
    }
}

.btn-chat-popup-close {
    position: absolute;
    right: 0;
    top: 0;

	background: none;
	border: none;
	cursor: pointer;
}
