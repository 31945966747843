.tarife {
    font-size: 18px;
    hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
}

.tarife img {
    width: auto;
    max-width: 100% !important;
    height: auto;
}

.tarife .tarifeBacklink {
    display: block;
    margin-bottom: 1em;

    font-size: 1.25em;
}

.tarife > h1 {
    font-size: 2.5em;
}

.tarife > h2 {
    font-size: 1.25em;
}

.tarife .indexSection {
    margin-bottom: 3em;
}

.tarife .indexSection > ul li {
    border-bottom: 1px solid #E4E4E4;
}

.tarife .indexSection > ul li:first-child {
    border-top: 1px solid #E4E4E4;
}

.tarife .indexSection > ul li a {
    position: relative;

    display: block;
    padding: 12px;
    padding-right: 40px;

    color: #222222;
    font-size: 1.5em;
    font-weight: 700;
    text-decoration: none;
}

.tarife .indexSection > ul li a:hover {
    color: #666666;
}

.tarife .indexSection .ic-nav-forward {
    position: absolute;
    top: 50%;
    right: 12px;

    transform: translateY(-50%);
}

.tarife .pdfDocu {
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: middle;
}

.tarife .pdfDocuIcon {
    display: inline-block;
}

.tarife .tarifeKlappbox {
    border-bottom: 1px solid #E4E4E4;
}

.tarife .tarifeKlappbox h2,
.tarife .tarifeKlappbox h3 {
    margin: 0;
}

.tarife .tarifeKlappbox h2 {
    font-size: 1.5em;
}

.tarife .tarifeKlappbox h3 {
    font-size: 1.25em;
}

.tarife .tarifeKlappbox > h2 > a,
.tarife .tarifeKlappbox > h3 > a,
.tarife .tarifeKlappboxHandle {
    display: flex;

    position: relative;

    width: 100%;
    padding: 12px 12px 12px 12px;

    cursor: pointer;

    color: #222222;
    font-weight: 700;
    text-decoration: none;
}

.tarife .tarifeKlappbox > h2 > a:hover,
.tarife .tarifeKlappbox > h3 > a:hover {
    color: #666666;
}

.tarife .tarifeKlappbox .tarifeKlappboxIcon {
    flex: 0 0 20px;

    position: relative;
    top: 4px;

    margin-right: 16px;
    vertical-align: baseline;
}

.tarife .tarifeKlappbox > h2 > a:hover > .tarifeKlappboxIcon,
.tarife .tarifeKlappbox > h3 > a:hover > .tarifeKlappboxIcon {
    background-image: url(../images/icons/spritemap-postbus.svg#arrow_down-view);
}

.tarife .tarifeKlappbox h2 > a:focus > .tarifeKlappboxIcon,
.tarife .tarifeKlappbox h2 > a:active > .tarifeKlappboxIcon,
.tarife .tarifeKlappbox h3 > a:active > .tarifeKlappboxIcon,
.tarife .tarifeKlappbox h3 > a:focus > .tarifeKlappboxIcon {
    background-image: url(../images/icons/spritemap-black.svg#arrow_down-view);
}


.tarife .tarifeKlappbox.expandable-open > h2 > a > .tarifeKlappboxIcon,
.tarife .tarifeKlappbox.expandable-open > h3 > a > .tarifeKlappboxIcon {
    background-image: url(../images/icons/spritemap-postbus.svg#arrow_up-view);
}

.tarife .tarifeKlappbox > h2 > a .az,
.tarife .tarifeKlappbox > h3 > a .az,
.tarife .tarifeKlappboxHandle > .az {
    flex: 0 0 80px;

    color: #666666;
    white-space: nowrap;
}

.tarife .tarifeKlappboxHandleNoAnchor {
    display: flex;

    padding-left: 48px;
    cursor: auto;
}

.tarife .tarifeKlappboxHandleText {
    flex: 0 0 auto;

    width: ~"calc(100% - 120px)";
}

.tarife h2,
.tarife h3,
.tarife p {
    position: relative;
}

.tarife .tarifeKlappbox h2 .az,
.tarife .tarifeKlappbox h3 .az,
.tarife .tarifeKlappbox p .az {
    display: inline-block;
    margin-right: 16px;
}

/** Other levels */

.tarife .tarifeKlappbox > div > h3,
.tarife .tarifeKlappbox > div > h4,
.tarife .tarifeKlappbox > div > p,
.tarife .tarifeKlappbox > div > ul,
.tarife .tarifeKlappbox > div > ol,
.tarife .tarifeKlappbox .bulletlist {
    margin: 0.5em 0;
    padding-left: 175px; // 145px;
}

.tarife .tarifeKlappbox > div > h3 {
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
}

.tarife .tarifeKlappbox > div > h3 .az,
.tarife .tarifeKlappbox > div > p  .az,
.tarife .tarifeKlappbox > div > ul .az,
.tarife .tarifeKlappbox > div > ol .az {
    position: absolute;
    // top: 2px;
    top: 0;
    left: 48px;

    display: block;

    // font-size: 88%;
}

.tarife .tarifeKlappbox .bulletlist {
    padding-left: 195px;

    list-style: disc outside none;
}

.tarife .tarifeKlappbox .bulletlist .dashlist {
    padding-left: 16px;
    margin-bottom: 0;

    list-style: circle outside none;
}

.tarife .tarifeKlappbox ol {
    padding-left: 195px;
}

.tarife .tarifeKlappbox table {
    width: 100%;
}

.tarife .tarifeKlappbox {
    table,
    tr,
    th,
    td {
        background: none;
    }
}

@media @medium-devices-and-smaller {
    .tarife .tarifeKlappbox table {
        padding: 4px;
        font-size: 0.8em;
    }
}

@media @small-medium-devices {
    .tarife .tarifeKlappbox table {
        display: block;
        overflow: scroll;
    }
}

.tarife .tarifeKlappbox > div > .neueVersion {
    margin-left: 20px;
    margin-bottom: 1em;
    padding-left: 1.5em;

    border-left: 2px solid @tint;
}

.tarife .tarifeKlappbox > div > .neueVersion > p {
    padding-left: 128px;
}

.tarife .tarifeKlappbox > div > .neueVersion > p .az {
    position: absolute;
    left: 0;
}

.tarife .tarifeKlappbox > div > .neueVersion > ul,
.tarife .tarifeKlappbox > div > .neueVersion > ol {
    padding-left: 144px;
}

.tarife .tarifeKlappbox > div > .neueVersion table {
    padding-left: 0;
}

.tarife .tarifeKlappbox .neueVersion .aenderungAnfang,
.tarife .tarifeKlappbox .neueVersion .aenderungEnde {
    margin-bottom: 1em;

    font-style: italic;
}

/** First level */

.tarife > .tarifeKlappbox > div > h2,
.tarife > .tarifeKlappbox > div > h3,
.tarife > .tarifeKlappbox > div > h4,
.tarife > .tarifeKlappbox > div > p,
.tarife > .tarifeKlappbox > div > .neueVersion {
    padding-left: 48px;

    &.has-az {
        padding-left: 145px;

        & ~ p {
            padding-left: 145px;
        }
    }
}

.tarife > .tarifeKlappbox > div > h4 {
    font-size: 1.15em;
}

.tarife > .tarifeKlappbox > div > p > .az {
    position: static;
    float: left;
}

.tarife > .tarifeKlappbox > div > p.has-az > .az {
    position: absolute;
    float: none;
}

.tarife > .tarifeKlappbox > div > .bulletlist {
    padding-left: 64px;

    list-style: disc outside none;
}

.tarife > .tarifeKlappbox > div > ol  {
    padding-left: 64px;
}

.tarife > .tarifeKlappbox table {
    padding-left: 48px;

    background: none;
}

.tarife > .tarifeKlappbox > div > .neueVersion {
    padding-left: 1.5em;
}

.tarife > .tarifeKlappbox > div > .neueVersion > p {
    padding-left: 0;
}

.tarife > .tarifeKlappbox > div > .neueVersion > p.has-az {
    padding-left: 95px;
}

.tarife > .tarifeKlappbox > div > .neueVersion > p > .az + strong {
    padding-left: 42px;
}

.tarife > .tarifeKlappbox > div > .neueVersion > ul,
.tarife > .tarifeKlappbox > div > .neueVersion > ol {
    padding-left: 16px;
}

/** Override */

.tarife .tarifeKlappbox table p {
    padding-left: 0;
}

// .tarife > table {
//     background: transparent;
// }

// .tarife > table p {
//     padding-left: 0;
// }

@media @mini-small-devices {
    .tarife .tarifeKlappbox h2 {
        font-size: 1em;
    }

    .tarife .tarifeKlappbox .tarifeKlappboxIcon {
        top: 0;
    }

    .tarife .tarifeKlappbox > h2 > a .az,
    .tarife .tarifeKlappbox > h3 > a .az,
    .tarife .tarifeKlappboxHandle > .az {
        flex: 0 0 50px;
    }

    .tarife .tarifeKlappboxHandleText {
        width: ~"calc(100% - 90px)";

        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tarife .indexSection > ul li a {
        font-size: 1.25em;
    }
}
