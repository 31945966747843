:root {
    --tint: @tint;
}

/** ".is-tabbing" is added via JavaScript and shows the outline */
.is-tabbing {
    button::-moz-focus-inner {
        border: 0;
    }

    a:focus,
    button:focus,
    div:focus,
    input[type="submit"]:focus,
    .input-radio input:focus + span,
    .options-field input:focus + svg,
    .autocomplete-panel button.close-btn:focus,
    .oebb-dropdown-panel__btn-close--active,
    input[type="checkbox"]:focus + checkbox-icon,
    input[type="radio"]:focus + radiobutton-icon,
    input[type="radio"]:focus + radiobutton-checked-icon {
        outline: 2px dotted @tint-focus;
        outline-offset: 2px;
        z-index: 10;
    }

    .service-nav a:focus {
        position: relative;
        outline-offset: -4px;
    }

    .main-nav {
        a:focus,
        button:focus {
            outline-offset: -4px;
        }

        & > li > a.open-link,
        & ul > li > a.nav-sub-panel-link {
            z-index: 15;
            background: transparent;
        }

        & > li > a.open-link:focus {
            background-color: #383736;
        }
        & ul > li > a.nav-sub-panel-link:focus {
            background-color: #565656;
        }

        & ul > li > .nav-sub-panel-link:focus {
            outline-color: @tint;
        }
    }

    .train-equipment .backward-link,
    .train-equipment .forward-link {
        &:focus {
            z-index: 99999;

            outline-offset: -2px;
        }
    }
}

.no-js {
    .js-lazy-image {
        display: none;
    }
}

ul[class$="nav"], ul[class$="nav right"] {
    font-size: 0;
}

ul[class$="-nav"]>li, ul[class$="-nav right"]>li {
    display: inline;
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 0;
}

ul[class$="-nav"]>li a, ul[class$="-nav right"]>li a {
    font-size: 18px;
}

ul[class$="-nav"]>li a:after, ul[class$="-nav right"]>li a:after {
    clear: both;
}

@media @big-devices-and-smaller  {
    body {
        padding: 0;
        width: 100%;
    }
    .ocopen {
        overflow: hidden;
    }
    .ocopen body {
       overflow: auto; 
    -webkit-overflow-scrolling: touch;
    }
   
    .ocopen .main-menu {
        margin-left: 0;
    }
    .scroll-container {
            width: 100%;
        max-width: 1280px;
        margin-left: 0;
        overflow: hidden;
        transition: margin-left 0.4s linear;
    }
    .ocopen .scroll-container {
        margin-left: @menuWidthLarge;
    }
    header {
        margin-left: 0;
        transition: margin-left 0.4s linear;
    }
    .ocopen header {
        margin-left: @menuWidthLarge;
    }
    .content {
        margin-top: 0;
        padding-top: 70px;
    }
}

@media @small-devices {
    .ocopen .scroll-container {
        margin-left: @menuWidthMedium;
    }
    
    .ocopen header {
        margin-left: @menuWidthMedium;
    }

    .content {
        padding-top: 50px;
    }
}

@media @mini-devices {
    .ocopen .scroll-container {
        margin-left: @menuWidthSmall;
    }
    
    .ocopen header {
        margin-left: @menuWidthSmall;
    }
}

@media @big-devices-and-smaller {
    .indented-module {
        padding: 20px 20px;
    }
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.content {
    color: #666666;
}
/* 
.content b,
.content strong {
    color: #000;
} */
.content h1,
.content .h1,
.content .biggest {
    font-size: 60px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    margin-bottom: 24px;
    margin-top:0;
}
.content h2,
.content .h2,
.content .bigger {
    font-size: 34px;
    font-weight: normal;
    width: 100%;
    margin-bottom: 24px;
}

@media @small-devices {
    .content h2,
    .content .h2,
    .content .bigger {
        font-size: 28px;
        font-weight: normal;
        width: 100%;
        margin-bottom:20px;
    }
}

@media @mini-small-devices {
    .content h2,
    .content .h2,
    .content .bigger {
        font-size: 24px;
    }
}

.content h3,
.content .h3,
.content .big {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 16px;
}

.content h4,
.content .h4,
.content .small {
    font-size: 20px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 12px;
}

.content h5,
.content .h5,
.content .smaller {
    font-size: 18px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 6px;
}

.content h6,
.content .h6,
.content .smallest {
    font-size: 16px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 4px;
}

.content .biggest,
.content .bigger,
.content .big,
.content .small,
.content .smaller,
.content .smallest {
    &--normal {
        font-weight: normal;
    }
}

.content .top-line {
    transform: translateY(-50%);

    &:before {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background: currentColor;
        position: absolute;
        top: -22px;
        left: 0;
    }
}

.content p {
    line-height: 1.5;
    margin-bottom: 20px;
}

.content .font-size--middle {
    font-size: 1.2em;
}

.content .font-size--large {
    font-size: 1.4em;
}

.content .font-size--xlarge {
    font-size: 1.675em;
}

.content .font-size--xxlarge {
    font-size: 2.5em;
}

.content .font-size--xxxlarge {
    font-size: 3.5em;
}

.content .font-alignment--centered {
    text-align: center;
}

.content .font-alignment--right {
    text-align: right;
}

.content .font-uppercase {
    text-transform: uppercase;
}

.content .font-color--red {
    color: #E2002A;
}

.content .font-color--white {
    color: #ffffff;
    padding: 20px;
}

.content .background-color--white {
    background: white;
    padding: 40px 20px;
}

.content .background-color--black {
    background: black;
    padding: 20px;
}

.content .background-color--blackTransparent {
    background: rgba(0, 0, 0, .8);
    padding: 20px;
}

.content .callout {
    margin-bottom: 20px;
}

.content .callout__heading {
    display: block;
    margin-bottom: 6px;
    padding-left: 16px;
    color: @tint;
    font-size: 12px;
    font-family: @font-bold;
    font-weight: normal;
    line-height: 16px;
    text-transform: uppercase;
}

.content .callout__body {
    position: relative;
    padding-left: 16px;
}

.content .callout__body::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: '';
    display: block;
    width: 4px;
    background-color: @tint;
}

.content .row a,
.connectionsViewPanel a {
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
}

a[name] {
    position:relative;
    display: block; 
    margin-top: -80px; 
    height: 80px; 
    visibility: hidden;
}

a[name]:before { 
    position:relative;
    display: block; 
    content: " "; 
    margin-top: -80px; 
    height: 80px; 
    visibility: hidden; 
}

.content .back-link {
    display: block;
    margin-bottom: 20px;

    font-weight: bold;
    -webkit-font-smoothing: antialiased;
}

.content .back-link .ic-nav-backward {
    height: 14px;

    background-image: url(../images/icons/spritemap-postbus.svg#arrow_left-view);
}

.content .back-link:hover .ic-nav-backward {
    background-image: url(../images/icons/spritemap-black.svg#arrow_left-view);
}

.action-btn-container {
    display: contents;
}

.action-btn,
.content a.action-btn,
.content a.more-btn,
.content .more-btn {
    background: @tint;
    border: 1px solid @tint;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    display: block;
    text-align: center;
    padding: 12px 20px 12px 20px;
    margin-bottom: 5px;
    max-width: 350px;
    text-decoration: none;
    cursor: pointer;
    font-family: inherit;

    &--right {
        display: block;
        margin-left: auto;
    }

    &-position--centered {
        text-align: center;
    }

    &-position--right {
        text-align: right;
    }

    &.action-btn--oebb-red {
        background: @color-oebb-red;
        border: 1px solid @color-oebb-red;
    }

    &.action-btn--oebb-red-inversed {
        background: none;
        border: 1px solid @color-oebb-red;

        color: @color-oebb-red;

        .ic-nav-forward {
            background-image: url(../images/icons/spritemap-oebb.svg#arrow_right-view);
        }

        .ic-tickets {
            background-image: url(../images/icons/spritemap-oebb.svg#ticketshop-view);
        }

        .ic-luggage {
            background-image: url(../images/icons/spritemap-oebb.svg#luggage-view);
        }

        .ic-transport {
            background-image: url(../images/icons/spritemap-oebb.svg#transport-view);
        }
    }

    &.action-btn--nj-blue {
        background: @color-nightjet;
        border: 1px solid @color-nightjet;
    }

    &.action-btn--nj-blue-inversed {
        background: none;
        border: 1px solid @color-nightjet;

        color: @color-nightjet;

        .ic-nav-forward {
            background-image: url(../images/icons/spritemap-black.svg#arrow_right-view);
        }

        .ic-tickets {
            background-image: url(../images/icons/spritemap-black.svg#ticketshop-view);
        }

        .ic-luggage {
            background-image: url(../images/icons/spritemap-black.svg#luggage-view);
        }

        .ic-transport {
            background-image: url(../images/icons/spritemap-black.svg#transport-view);
        }
    }
}

.action-btn:active,
.action-btn:focus,
.action-btn:hover,
.content a.action-btn:active,
.content a.action-btn:focus,
.content a.action-btn:hover,
.content a.more-btn:active,
.content a.more-btn:focus,
.content a.more-btn:hover,
.content .more-btn:active,
.content .more-btn:focus,
.content .more-btn:hover {
    background: #fff;
    border: 1px solid @tint;
    color: @tint;

    &.action-btn--oebb-red {
        border-color: @color-oebb-red;
        color: @color-oebb-red;

        .ic-nav-forward {
            background-image: url(../images/icons/spritemap-oebb.svg#arrow_right-view);
        }

        .ic-tickets {
            background-image: url(../images/icons/spritemap-oebb.svg#ticketshop-view);
        }

        .ic-luggage {
            background-image: url(../images/icons/spritemap-oebb.svg#luggage-view);
        }

        .ic-transport {
            background-image: url(../images/icons/spritemap-oebb.svg#transport-view);
        }
    }

    &.action-btn--oebb-red-inversed {
        border-color: @color-oebb-red;
        color: @color-oebb-red;
    }

    &.action-btn--nj-blue {
        border-color: @color-nightjet;
        color: @color-nightjet;

        .ic-nav-forward {
            background-image: url(../images/icons/spritemap-black.svg#arrow_right-view);
        }

        .ic-tickets {
            background-image: url(../images/icons/spritemap-black.svg#ticketshop-view);
        }

        .ic-luggage {
            background-image: url(../images/icons/spritemap-black.svg#luggage-view);
        }

        .ic-transport {
            background-image: url(../images/icons/spritemap-black.svg#transport-view);
        }
    }

    &.action-btn--nj-blue-inversed {
        border-color: @color-nightjet;
        color: @color-nightjet;
    }
}

.content .action-btn .action-btn__text {
    padding: 0 2px 0 4px;
    vertical-align: middle;
}

.content .action-btn.action-btn--inversed,
.content a.action-btn.action-btn--inversed {
    background: none;
    color: @tint;

    .ic-nav-forward {
        background-image: url(../images/icons/spritemap-postbus.svg#arrow_right-view);
    }

    .ic-tickets {
        background-image: url(../images/icons/spritemap-postbus.svg#ticketshop-view);
    }

    .ic-luggage {
        background-image: url(../images/icons/spritemap-postbus.svg#luggage-view);
    }

    .ic-transport {
        background-image: url(../images/icons/spritemap-postbus.svg#transport-view);
    }
}

.action-btn-translated,
.content .action-btn-translated {
    position: relative;

    .action-btn {
        position: absolute;
        right: 100px;
        bottom: -28px;
        z-index: 5;

        min-width: 255px;
        margin: 0;
        padding: 15px 30px;

        font-size: 20px;
        font-weight: bold;
        -webkit-font-smoothing: antialiased;

        @media @big-devices-and-smaller {
            right: 15px;
        }

        @media @small-medium-devices {
            right: auto;
            left: 50%;

            transform: translateX(-50%);
        }

        @media @mini-small-devices {
            position: static;

            width: 100%;
            max-width: none;

            transform: none;
        }
    }
}

.action-btn-floated,
.content .action-btn-floated {
    position: fixed;
    right: 20px;
    bottom: 30px;
    z-index: 9999;

    display: flex;
    align-items: center;

    background: #ffffff;
    border-radius: 30px;
    box-shadow: 1px 2px 7px rgba(0,0,0,.35);

    color: #444444;
	font-weight: bold;
    -webkit-font-smoothing: antialiased;

    &--right {
        right: 20px;
        left: auto;
    }

    &--centered {
        right: auto;
        left: 50%;

        transform: translateX(-50%);
    }

    &--left {
        right: auto;
        left: 20px;
    }

    .action-btn-floated__caption {
        display: flex;
        align-items: center;
        max-width: 5.5em;
        margin: 0 12px 0 10px;
    }

    .action-btn-floated__text {
        margin-right: 6px;
    }

    .action-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        min-width: 0;
        max-width: none;
        height: 60px;
        margin: 0;

        border-radius: 50%;

        .ic-tickets {
            background-size: 20px;
        }

        .ic-nav-forward {
            display: none;
        }

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            content: '';
        }
    }

    /* screen-reader-text */
    .action-btn__text {
        position: absolute;

        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;

        border: 0;
        clip-path: inset(50%);

        white-space: nowrap;
    }
}

.action-btn,
.content a.action-btn {
    display: inline-block;
    width: auto;
    min-width: 220px;
    min-height: 50px;
    margin-bottom: 20px;
    cursor: pointer;
    font-family: inherit;
    font-size: 20px;
    text-decoration: none;

    &--right {
        display: block;
        margin-left: auto;
    }
}

.content .col-1 .action-btn,
.content .col-2 .action-btn,
.content .col-3 .action-btn,
.content .col-4 .action-btn {
    width: 100%;
}

@media @medium-devices-and-smaller {
    .content .col-1 .action-btn,
    .content .col-2 .action-btn,
    .content .col-3 .action-btn,
    .content .col-4 .action-btn {
        width: auto;
    }
}

@media @mini-small-devices {
    .content .col-1 .action-btn,
    .content .col-2 .action-btn,
    .content .col-3 .action-btn,
    .content .col-4 .action-btn {
        min-width: 100%;
    }
}

.content ul {
    list-style: none;
    margin-bottom: 20px;
}

.content ol {
    margin: 1em 0;
    padding-left: 1em;
}

.content .row li {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
    padding-left: 26px;
    margin-bottom: 6px;
    color: #666666;
    line-height: 1.5;
}

.content .row li::before,
.content .row li.link::before {
    content: "•";
    color: @tint;
    font-size: 40px;
    line-height: 30px;
    display: inline-block;
    position: absolute;
    top: 14px;
    margin-top: -20px;
    left: 0;
}

.content .hook li::before,
.content li.hook::before {

    content: " ";
  width:20px;
  height: 20px;
    display: inline-block;
    position: absolute;
    top: -2px;
    margin-top: 0;
    left: 2px;
     background-size: 12px 12px;
      background: url(../images/icons/spritemap-lightgreen.svg#hook-view) no-repeat left center;
}
.content .download li::before,
.content li.download::before {
   
    content: " ";
  width:20px;
  height: 20px;
    display: inline-block;
    position: absolute;
    top: -2px;
    margin-top: 0;
    left: 2px;
     background-size: 12px 12px;
      background: url(../images/icons/spritemap-postbus.svg#download-view) no-repeat left center;
}

.content .row li[class*="ic-address"]::before,
.content .row li[class*="ic-list"]::before {
    width: 20px;
    height: 30px;

    background: no-repeat center center scroll;
    content: '';
}

.content .row li[class*="ic-list"]::before {
    background-position: center 3px;
}

.content .row li.ic-address-person::before {
    background-image: url(../images/icons/spritemap-darkgray.svg#user-view);
}

.content .row li.ic-address-mail::before {
    background-image: url(../images/icons/spritemap-darkgray.svg#mail-view);
}

.content .row li.ic-address-web::before {
    background-image: url(../images/icons/spritemap-darkgray.svg#home-view);
}

.content .row li.ic-address-info::before {
    background-image: url(../images/icons/spritemap-darkgray.svg#luggage_tag-view);
}

.content .row li.ic-address-phone::before {
    background-image: url(../images/icons/spritemap-darkgray.svg#phone-view);
}

.content .row li.ic-list-bus::before {
    background-image: url(../images/icons/spritemap-darkgray.svg#bus-view);
}

.content .row li.ic-list-calendar::before {
    background-image: url(../images/icons/spritemap-darkgray.svg#calendar-view);
}

.content .row li.ic-list-map-geo::before {
    background-image: url(../images/icons/spritemap-darkgray.svg#map-geo-view);
}

.content .row li.ic-list-pin::before {
    background-image: url(../images/icons/spritemap-darkgray.svg#pin-view);
}

.content .row li.ic-list-signpost::before {
    background-image: url(../images/icons/spritemap-darkgray.svg#signpost-view);
}

.content .row li.ic-list-thumb-up::before {
    background-image: url(../images/icons/spritemap-darkgray.svg#thumb-up-view);
}

.video-container.video--ready {
    border: 1px solid #E4E4E4;
    padding: 10px;
    margin-bottom: 20px;

    &.video--activated {
        border: none;
        padding: 0;

        button {
            color: @tint;
            background: transparent;
            border-color: transparent;
            text-decoration: underline;
            padding: 0;
            margin: -15px 0 20px auto;
            min-width: 0;
            min-height: 0;

            &:hover,
            &:focus {
                color: #666;
            }
        }
    }

    button {
        margin-top: 0;
        margin-bottom: 0;

        &:hover .ic-play-white,
        &:focus .ic-play-white {
            background-image: url(../images/icons/spritemap-postbus.svg#play-view);
        }
    }
}

.video-container .video__disclaimer {
    margin-bottom: 10px;
}

.video--activated .video__disclaimer {
    display: none;
}

.video--activated .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
    margin-bottom: 20px;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.content img {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    border: 0;
}

.content .content-image {
    margin-bottom: 20px;
}

.content .row img {
    width: auto;
    max-width: 100%;
}

.content .video-internal {
    position: relative;

    button {
        position: absolute;
        bottom: 20px;
        left: 50%;
        z-index: 1;

        display: block;
        padding: 10px;

        background-color: rgba(0, 0, 0, 0.8);
        border: none;
        border-radius: 50%;
        cursor: pointer;
        opacity: 0;
        transform: translateX(-50%);
        transition: opacity 0.2s ease;

        &:focus {
            opacity: 1;
        }

        & .ic-play-white {
            transform: translate(2px, -1px);
        }

        & .ic-pause-white {
            transform: translate(0, -1px);
        }

        &[aria-pressed="true"] {
            & .ic-play-white {
                display: none;
            }

            & .ic-pause-white {
                display: block;
            }
        }

        &[aria-pressed="false"] {
            & .ic-play-white {
                display: block;
            }

            & .ic-pause-white {
                display: none;
            }
        }
    }

    &:focus,
    &:hover {
        button {
            opacity: 1;
        }
    }

    & video {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }
}

iframe {
    border: 0;
    border-width: 0;
}

.piwik-modul {
    width: 100%;
}

/* Magnolia edit mode */

.edit-mode .content .row .videoWrapper img {
    width: 100%;
    height: auto;
}

// .hidden-slot {
//     display: none;
// }

// .loaded .hidden-slot {
//     display: block;
// }