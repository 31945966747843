/** Link List */

.content .link-list {
    margin-bottom: 20px;
    list-style: none;
}

.content .link-list li,
.content .link-list li.link-list__item {
    margin: 0;
    padding: 0;
}

.content .link-list li a,
.content .link-list li.link-list__item a {
    display: block;
    width: 100%;
    padding-left: 28px;
    padding-right: 8px;
    text-decoration: none;
    border-top: 1px solid #E4E4E4;
    border-bottom: none;
    padding-bottom: 8px;
    padding-top: 8px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    color:#666;
    background-image: url(../images/icons/spritemap-black.svg#arrow_right-view);
    background-position: 5px 12px;
    background-repeat: no-repeat;
    background-size: 12px 12px;
}

.content .link-list li.link-list__item--external a {
    padding-right: 28px;
}

.content .link-list li a:active,
.content .link-list li a:focus,
.content .link-list li a:hover,
.content .link-list li.link-list__item a:active,
.content .link-list li.link-list__item a:focus,
.content .link-list li.link-list__item a:hover {
    background-color: #E4E4E4;
    color: #000;
}

.content .link-list li::before,
.content .link-list li.link-list__item::before {
    content: none;
}

.content .link-list__item--download .link-list__item-filesize {
    padding: 0 8px;
    text-align: right;
    float: right;
    color: #666666;
}

.content .link-list .ic-external-link {
    position: absolute;
    top: 0;
    right: 6px;
    height: 100%;
    background-position: center center;
    background-size: 14px;
}

.content .link-list a:focus .ic-external-link,
.content .link-list a:hover .ic-external-link,
.content .link-list a:active .ic-external-link {}

.content .link-list .link-list-item-meta {
    font-weight: normal;
}

@media @medium-devices-and-smaller {
    .col-8 ~ .col-4 > .link-list{
        margin-top: 0;
    }
}

@media @small-devices {
    .content .link-list__item--download .link-list__item-filesize {
        display:block;
        padding: 0;
        float: none;
        width:100%;
        text-align: left;
    }

    .content .link-list__item--download .link-list__item-filesize::before {
        content:"("
    }

    .content .link-list__item--download .link-list__item-filesize::after {
        content:")"
    }
}

/** Link List Simple */

.content .link-list--simple li.link-list__item--page a,
.content .link-list--simple li.link-list__item--external a {
    background-image: url(../images/icons/spritemap-black.svg#arrow_right-view);
}

.content .link-list--simple li.link-list__item--download a {
    background-image: url(../images/icons/spritemap-darkgray.svg#download-view);
    background-size: 15px 15px;
}

.content .link-list--simple li.link-list__item--highlight a {
    background-image: url(../images/icons/spritemap-postbus.svg#arrow_right-view);
    color: @tint;
}


/** Link List Downloads */

.content .link-list--downloads li a,
.content .link-list--downloads li.link-list__item a {
    background-color: transparent;
    background-image: url(../images/icons/spritemap-postbus.svg#download-view);
    color: @tint;
}

.content .link-list--downloads li a:focus,
.content .link-list--downloads li a:hover,
.content .link-list--downloads li.link-list__item a:focus,
.content .link-list--downloads li.link-list__item a:hover {
    background-color: transparent;
    background-image: url(../images/icons/spritemap-darkgray.svg#download-view);
    color: #666666;
}

.content .link-list--downloads li.link-list__item--download a {
    background-image: url(../images/icons/spritemap-postbus.svg#download-view);
    background-size: 15px 15px;
}

.content .link-list--downloads li.link-list__item--page a,
.content .link-list--downloads li.link-list__item--external a {
    background-image: url(../images/icons/spritemap-postbus.svg#arrow_right-view);
}

.content .link-list--downloads li.link-list__item--page a:focus,
.content .link-list--downloads li.link-list__item--page a:hover,
.content .link-list--downloads li.link-list__item--external a:focus,
.content .link-list--downloads li.link-list__item--external a:hover {
    background-image: url(../images/icons/spritemap-black.svg#arrow_right-view);
}

@media @medium-devices-and-smaller {
    .col-8 ~ .col-4 > .vertical-scroll > .link-list--downloads {
        margin-top: 0;
    }
}

/** Link List Icons */

.content .link-list--icons {
    width: 100%;
    list-style-type: none;
    margin-bottom: 20px;
    font-size:0;
}

.content .link-list--icons-center {
    text-align: center;
}

.content .link-list--icons li,
.content .link-list--icons li.link-list__item {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    height: auto;
    font-size: 0;
    line-height: 20px;
}

.content .link-list--icons li a,
.content .link-list--icons li.link-list__item a {
    display: inline-block;
    // overflow: hidden;
    font-size: 16px;
    // text-overflow: ellipsis;
    // white-space:nowrap;
    text-align:center;
    padding: 8px 4px 8px 4px;
    background-image: none;
    border-top: none;
    border-bottom: none;
}

.content .link-list--icons li a:focus,
.content .link-list--icons li a:hover,
.content .link-list--icons li a:active,
.content .link-list--icons li.link-list__item a:focus,
.content .link-list--icons li.link-list__item a:hover,
.content .link-list--icons li.link-list__item a:active {
    background: #f5f5f5;
    color: @tint;
}

.content .link-list--icons img {
    width:80%;
    max-width:105px;
    display: block;
    margin: 4px auto 8px auto;
}

@media @small-medium-devices {
    .content .link-list--icons li,
    .content .link-list--icons li.link-list__item {
        width: 50%;
    }
}
