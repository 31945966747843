.content .quicklinks a {
    font-weight: normal;
    text-decoration: none;
}

.content .quicklinks-left a{
    display:block;
    position: relative;
    width:100%;
    color:#111B42;
font-size:24px;
padding:19px 50px;
border-bottom: 1px solid #E4E4E4;
border-right: 1px solid #E4E4E4;
max-height:66px;
}
.content .quicklinks-left a:last-child{
    border-bottom-width: 1px;
}


.content .quicklinks-left a:active,
.content .quicklinks-left a:focus,
.content .quicklinks-left a:hover {
    color:@tint;
}

.content .quicklinks-left .ic-bed,
.content .quicklinks-left .ic-bike,
.content .quicklinks-left .ic-car,
.content .quicklinks-left .ic-day,
.content .quicklinks-left .ic-destination,
.content .quicklinks-left .ic-info,
.content .quicklinks-left .ic-location,
.content .quicklinks-left .ic-luggage,
.content .quicklinks-left .ic-mail,
.content .quicklinks-left .ic-map,
.content .quicklinks-left .ic-message,
.content .quicklinks-left .ic-newspaper,
.content .quicklinks-left .ic-niceprice,
.content .quicklinks-left .ic-payment,
.content .quicklinks-left .ic-route,
.content .quicklinks-left .ic-tickets,
.content .quicklinks-left .ic-user,
.content .quicklinks-left .ic-warning,
.content .quicklinks-left .ic-wheelchair {
    position: absolute;
    left: 14px;
    width:30px;
    height:20px;
    background-size: 20px 20px;
    top:50%;
    margin-top: -10px;
}


.content .quicklinks-left .ic-forward{
    position: absolute;
    right:4px;
    top:50%;
    margin-top: -10px;
}

// Mittlerte Spalte
.content .quicklinks-middle{
    position:relative;
     height:197px;
}
.content .quicklinks-middle a{
    display:block;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
   border-right: 1px solid #E4E4E4;
   color:#111B42;
   margin-left:-10px;
   padding: 15px;
}


.content .quicklinks-middle a:active,
.content .quicklinks-middle a:focus,
.content .quicklinks-middle a:hover {
    color:@tint;
}
.content .quicklinks-middle .ic-transport {
    position: absolute;
    left: 0;
    top:66px;
    width:90px;
    height:70px;
    background-size: 86px 70px;

}
.content .quicklinks-middle .quicklinks-heading {
    display: block;
    font-weight: normal;
    padding-top: 4px;
}

.content .quicklinks-middle p {
    margin-bottom: 10px;
    padding-left: 90px;
    padding-top: 16px;
    padding-right:5px;
}

.content .quicklinks-middle figure {
    float: left;
    width: 80px;
    height: 80px;
    margin: 16px 0 0 0;
}

.content .quicklinks-middle figure img {
    width: 100%;
    height: auto;
}

.content .quicklinks-middle .quicklinks-pseudo-link {
    display: block;
    margin-top: 10px;
    padding: 0;

    color: @tint;
    text-decoration: underline;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;

    .ic-forward {
        height: 19px;

        background-image: url(../images/icons/spritemap-postbus.svg#arrow_right-view);
    }
}

// Rechte Spalte
.content .quicklinks-right{
      position:relative;
     height:197px;
    margin-left:-10px;
    padding:15px 20px;
}

.content .quicklinks-right .quicklinks-heading {
    display: flex;

    font-weight: normal;
    padding-top: 4px;
}

.content .quicklinks-right .quicklinks-heading span {
    display: block;
    width: 100%;
}

.content .quicklinks-right .quicklinks__weather {
    width: auto;
    min-width: 115px;
    margin-left: 5px;

    float: right;

    font-size: 20px;
    text-align: right;
}

.content .quicklinks-right .quicklinks-subheading {
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    font-size:24px;
}

.content .quicklinks-right a{
    display:block;
  position:absolute;
  bottom:20px;
  left:15px;
  width:auto;
  max-width: 200px;
 background:@tint;
   border: 1px solid @tint;;
   color:#fff;
   font-size: 18px;
   font-weight: bold;
   -webkit-font-smoothing: antialiased;
 white-space: nowrap;
 overflow:hidden;
 text-overflow: ellipsis;
   padding: 10px 20px;
}
.content .quicklinks-right a:active,
.content .quicklinks-right a:focus,
.content .quicklinks-right a:hover {
    color:@tint;
    background: #fff; 
}
.quicklinks-price{
     display:block;
  position:absolute;
  bottom:25px;
  right:20px;
  width:auto; 
   color:@tint;
   font-size: 24px;
   font-weight: bold;
   -webkit-font-smoothing: antialiased;
}

@media @medium-devices-and-smaller {
    .content .quicklinks-left a {
        
        border-right-width: 0;
        max-height: none;
    
    }

    .content .quicklinks-left a:last-child {
        border-bottom-width: 1px;
    
    }

    .content .quicklinks-middle {
        height: auto;
    }

    .content .quicklinks-middle a {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height:140px;
        padding: 15px 20px;
        margin-left:0;
        border-right-width: 0;
        border-bottom: 1px solid #E4E4E4;
    }

    .content .quicklinks-right {
        height: auto;
        min-height:150px;
        margin-left: -10px;
        margin-right: -10px;
        padding: 15px 25px 15px 45px;
    }

    .content .quicklinks-right a {
        display: inline-block;
        position: relative;
        top: 0;
        left: 0;
        width: auto;
        max-width: 100%;
        height: 100%;
        border-right: 1px solid #E4E4E4;
        margin-left: 0;
        padding: 15px 15px;
    }

    .content .quicklinks-right .more-btn,
    .content .quicklinks-right a.more-btn {
        border-right: 1px solid @tint;
    }
}

@media @mini-small-devices {
    .content .quicklinks-right .quicklinks-price {
        position: static;

        display: block;
        margin-bottom: 24px;
    }
}

// @media @mini-devices {
//    .content .quicklinks-right a{
//        max-width:150px;
//        padding: 7px 7px;
//     }

//     .quicklinks-price{
//         bottom: 20px;
//     }
// }
